import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Controller from "../Containers/Controller_Cont.js";
import Drawer from "../Containers/DrawerCont.js";
import Navbar from '../../Pages/Home/Component/Nav.js'
import Home from '../../Pages/Home/Component/Home.js'
import Pricing from '../../Pages/Pricing/Components/Pricing1.js';
import Service from '../../Pages/Service/Component/service.js'
import Contact from '../../Pages/contact/Component/contact.js'
import About from '../../Pages/About/Components/about.js'
import Footer from '../../Pages/Home/Component/Footer.js'
// import Login from "../../Auth/Containers/Login_Cont.js";
// import Profile from "../../Pages/Profile/Containers/ProfileCont.js";
class Routers extends Component {
  render() {
    const { login } = this.props;
    let body;
    console.log(login.type);

    if (login.isHome) {
      body = (
        <div>
          <main style={{ flexGrow: 1 }}>
            <Controller />
            <Routes>
              <Route path="/" element={<><Navbar/><Home /><Footer/></>} />
              <Route path="/pricing" element={<><Navbar/><Pricing/><Footer/></>}/>
              <Route path="/services" element={<><Navbar/><Service/><Footer/></>} />
              <Route path="/contact" element={<><Navbar/><Contact/><Footer/></>}/>
              <Route path='/about' element={<><Navbar/><About/><Footer/></>}/>
              
            </Routes>
          </main>
        </div>
      );
    } else {
      switch (login.type) {
        case "SA":
          console.log(login.type);
          body = (
            <div>
              {/* <Drawer type={login.type} /> */}
              <main style={{ flexGrow: 1, paddingTop: 0 }}>
                <Controller />
                <Routes>
                 
                </Routes>
              </main>
            </div>
          );
          break;
        case "E":
          body = (
            <div>
              <Drawer type={login.type} />
              <main style={{ flexGrow: 1, paddingTop: 70 }}>
                <Controller />
                <Routes>

                </Routes>
              </main>
            </div>
          );
          break;
        default:
          body = (
            <div>
              <Drawer type={login.type} />
              <main style={{ flexGrow: 1, paddingTop: 70 }}>
                <Controller />
                <Routes>

                </Routes>
              </main>
            </div>
          );
          break;
      }
    }

    return <Router>{body}</Router>;
  }
}

export default Routers;

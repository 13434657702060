import {
  ALLADDES,
  ADDESBYSTATUS,
  ALLCONTACTUS,
  ALLTESTDRIVE,
} from "./constant";
const initial_state = {
  all_addeslength: 0,
  all_addes: [],
  addesByStatus: [],
  all_contactlength: 0,
  all_contactUs: [],
  allTestDrive: [],
  allTestDriveLength: 0,
};
export default function reducer(state = initial_state, action) {
  switch (action.type) {
    case ALLADDES:
      return (state = {
        ...state,
        all_addes: action.payload,
        all_addeslength: action.count,
      });

    case ALLCONTACTUS:
      return (state = {
        ...state,
        all_contactUs: action.payload,
        all_contactlength: action.count,
      });
    case ALLTESTDRIVE:
      return (state = {
        ...state,
        allTestDrive: action.payload,
        allTestDriveLength: action.count,
      });

    case ADDESBYSTATUS:
      return (state = { ...state, addesByStatus: action.payload });
    default:
      return state;
  }
}

import React, { useState, useRef } from "react";
import { Typography, Box, Card, CardContent,List, ListItem, Divider, IconButton, Drawer, useMediaQuery,CardMedia,Grid } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos,  } from "@mui/icons-material";
import CardCover from '@mui/joy/CardCover';
import { useTheme } from "@mui/material/styles";
import video1 from '../../Video/tours.mp4'
import video2 from '../../Video/ngo.mp4'
import video3 from '../../Video/business.mp4'
import img1 from '../../images/best-web-design-company-logo.webp'
import card1 from '../../images/coding.webp'
import card2 from '../../images/seo.webp';
import card3 from '../../images/ads.webp';
import card4 from '../../images/hosting.webp';
import card5 from '../../images/ecommerce-logo.webp'
import card6 from '../../images/facebook-ads-logo.webp'
function Home() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [cardHeight, setCardHeight] = useState(0);
    const cardRef = useRef(null);
  
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const visibleCards = isDesktop ? 4 : isTablet ? 2 : 1;
  

  const cardData = [
    {
      title: "Bronze Website Development",
      subtitle: "One Page Website",
      price: "₹24,999",
      features: [
        "One Page Website",
        "Free Domain 1 Year*",
        "Free Hosting 1 Year",
        "Contact Form",
        "Responsive Design",
        "Professional Email Id 2",
      ],
      contact: "+91-9555-993-311",
    },
    {
      title: "Silver Website Development",
      subtitle: "Multi Page Website",
      price: "₹34,999",
      features: [
        "7 Pages Business website",
        "Customized Design",
        "Free Hosting 1 Year",
        "Free Domain 1 Year",
        "SEO Ready Website",
        "Business Email Id 10",
        "SSL Certificate",
        "Control Panel"
      ],
      contact: "+91-9555-993-311",
    },
    {
      title: "Gold Website Development",
      subtitle: "E-commerce Website",
      price: "₹54,999",
      features: [
        "E-commerce Website",
        "Customized Design",
        "Free Hosting 1 Year",
        "Free Domain 1 Year",
        "SEO Ready Website",
        "Business Email 10",
        "SSL Certificate",
        "Control Panel"
      ],
      contact: "+91-9555-993-311",
    },
    {
      title: "Gold+ Website Development",
      subtitle: "E-commerce Website",
      price: "₹99,999",
      features: [
        "E-commerce Website",
        "Wallet and Referral Integration",
        "Abandoned Checkout",
        "Customized Design",
        "Free Hosting 1 Year",
        "Free Domain 1 Year",
        "SEO Ready Website",
        "Business Email 15",
      ],
      contact: "+91-9555-993-311",
    },
    {
      title: "Platinum Website Development",
      subtitle: "Advanced E-commerce Website + Android App",
      price: "₹139,999",
      features: [
        "E-commerce Website",
        "Wallet and Referral Integration",
        "Abandoned Checkout",
        "Android Hybrid App",
        "Google Play Publishing",
        "Free Hosting 1 Year",
        "Free Domain 1 Year",
      ],
      contact: "+91-9555-993-311",
    },
    {
      title: "Diamond Website Development",
      subtitle: "Advanced E-commerce Website + iOs Hybrid App",
      price: "₹149,999",
      features: [
        "E-commerce Website",
        "Wallet and Referral Integration",
        "Abandoned Checkout",
        "Android Hybrid App",
        "Google Play Publishing",
        "Free Hosting 1 Year",
        "Free Domain 1 Year",
      ],
      contact: "+91-9555-993-311",
    },
  ];
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : cardData.length - visibleCards));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < cardData.length - visibleCards ? prevIndex + 1 : 0));
  };

  return (
    <div style={{overflowX:'hidden'}}>
      {/* Videos */}
      <Box sx={{backgroundColor:'#0F1214'}}>
      <Box sx={{ flexGrow: 1, padding: '30px', }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} >
            <Card component="li" sx={{ position: 'relative',marginTop:'70px' }}>
              <CardCover sx={{ height: '100%' }}>
                <video
                  autoPlay
                  loop
                  muted
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                >
                  <source src={video1} type="video/mp4" />
                </video>
              </CardCover>
              <CardContent>
              <Typography
                  level="body-lg"
                  textColor="#fff"
                  sx={{ fontWeight: 'lg', mt: { xs: 12, sm: 18 } }}
                >
                  Video
                </Typography>
                </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card component="li" sx={{ position: 'relative',marginTop:'70px' }}>
              <CardCover sx={{ height: '100%' }}>
                <video
                  autoPlay
                  loop
                  muted
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                >
                  <source src={video2} type="video/mp4" />
                </video>
              </CardCover>
              <CardContent>
                <Typography
                  level="body-lg"
                  textColor="#fff"
                  sx={{ fontWeight: 'lg', mt: { xs: 12, sm: 18 } }}
                >
                  Video
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Card component="li" sx={{ position: 'relative',marginTop:'70px'}}>
              <CardCover sx={{ height: '100%' }}>
                <video
                  autoPlay
                  loop
                  muted
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                >
                  <source src={video3} type="video/mp4" />
                </video>
              </CardCover>
              <CardContent>
              <Typography
                  level="body-lg"
                  textColor="#fff"
                  sx={{ fontWeight: 'lg', mt: { xs: 12, sm: 18 } }}
                >
                  Video
                </Typography>
                </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>


      {/* Carousel Section */}
      <Box display='flex' flexDirection="column" justifyContent='center'>
      <Typography variant="h3" sx={{textAlign:'center',color:'#54595F',fontFamily:"Montserrat, Sans-serif",fontSize:{xs:'25px',sm:'27px',lg:'30px'},fontWeight:'700',color:'white'}}>Pricing Plan for Website</Typography>
      <Box display="flex" alignItems="center" justifyContent="center" sx={{alignItems:"center",overflowX:'hidden'}} p={2}>
        <IconButton onClick={handlePrev} disabled={currentIndex === 0}>
          <ArrowBackIos sx={{color:'white'}} />
        </IconButton>

        <Box display="flex" gap={2} sx={{ overflow: "hidden", width: "80%" }}>
          {cardData.slice(currentIndex, currentIndex + visibleCards).map((card, index) => (
            <Card key={index} sx={{ width: "300px", backgroundColor: "#000", color: "#fff", border: "1px solid #00f", borderRadius: "8px" }}>
              <CardContent sx={{ textAlign: "center" }}>
                <Typography variant="h6" sx={{ fontWeight: "bold", color: "#fff", fontWeight: 'bolder' }}>
                  {card.title}
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "#bbb" }}>
                  {card.subtitle}
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: "bold", margin: "16px 0" }}>
                  {card.price}
                </Typography>
                <List dense sx={{ color: "#ccc" }}>
                  {card.features.map((feature, idx) => (
                    <ListItem key={idx} sx={{ padding: 0, fontSize: "14px" }}>
                      • {feature}
                    </ListItem>
                  ))}
                </List>
                <Divider sx={{ backgroundColor: "#333", margin: "16px 0" }} />
                <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                  TALK TO US
                </Typography>
                <Typography variant="subtitle2" sx={{ marginTop: "8px" }}>
                  {card.contact}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>

        <IconButton onClick={handleNext} disabled={currentIndex === cardData.length - visibleCards}>
          <ArrowForwardIos sx={{color:'white'}} />
        </IconButton>
      </Box>
      </Box>

          {/* content */}
      <Box display='flex' flexDirection="column" justifyContent='center'>
      <Typography variant="h3" sx={{textAlign:'center',fontFamily:"Montserrat, Sans-serif",margin:'30px',fontSize:{xs:'25px',sm:'27px',lg:'30px'},fontWeight:'700',color:'white'}}>24siteshop - Best Website Builder in India</Typography>
      <Box sx={{display:'flex',justifyContent:'start',alignItems:'center'}}><img src={img1} alt="Logo" style={{ height: '20%', width:'100%'}} /></Box>
      <Typography sx={{color:'white',width:'95%',marginTop:'30px',marginRight:'30px',marginBottom:'20px',textAlign:'start'}}>24siteshop is the best and affordable website development and website building company in India. Along with website building they provide digital marketing, SEO, PPC also. The reason behind that 24siteshop is the top website builder in India is that they provide very genuine and affordable service in website building. What i mean by genuine is that like they really tell customer where is the cost and how much is going also how much they are taking. For example, for a normal informative website, domain cost you can take Rs 850, Hosting Rs 2000 and for a development of 5 to 10 pages website it should be around Rs 3000 to RS 4000 max so if you do a total, final cost should be around Rs 6500 to Rs 8000. And if you look at their plan starting plan of 24siteshop is Rs 2999/- and for a Medium package cost is Rs 6500 and onwards. That’s why i think its a genuine and affordable website builder in India. If you want to checkout their plan in more details </Typography>

      </Box>

        {/* cards */}

        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Typography 
            variant="h4" 
            sx={{
            color: 'white',
            textAlign: 'center',
            fontFamily: "Montserrat, Sans-serif",
            margin: '30px',
            fontSize: { xs: '25px', sm: '27px', lg: '30px' },
            fontWeight: '700'
            }}
        >
            Services This Website Design Company Provide
        </Typography>

        <Box 
            sx={{ 
            display: "flex", 
            flexWrap: "wrap", 
            gap: '30px', 
            justifyContent: 'center', 
            alignItems: 'center' 
            }}
        >
            <Card 
            sx={{ 
                minWidth: 250, 
                flex: '1 1 calc(33.33% - 20px)', 
                borderRadius: '16px', 
                overflow: 'hidden', 
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                textAlign: 'center' 
            }}
            >
            <CardMedia
                component="img"
                src={card1}
                alt="Image 1"
                sx={{ 
                borderRadius: '50%', 
                width: '80px', 
                height: '80px', 
                objectFit: 'cover',
                margin: '20px 0' 
                }}
            />
            <CardContent>
                <Typography level="h6" sx={{ fontWeight: 'bold' }}>
                Website Development & Design
                </Typography>
                <Typography level="body2" sx={{ marginTop: '10px' }}>
                Crafting tailored websites, optimizing for visibility and sustained digital growth.
                </Typography>
            </CardContent>
            </Card>

            <Card 
            sx={{ 
                minWidth: 250, 
                flex: '1 1 calc(33.33% - 20px)', 
                borderRadius: '16px', 
                overflow: 'hidden', 
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                textAlign: 'center' 
            }}
            >
            <CardMedia
                component="img"
                src={card2}
                alt="Image 2"
                sx={{ 
                borderRadius: '50%', 
                width: '80px', 
                height: '80px', 
                objectFit: 'cover',
                margin: '20px 0' 
                }}
            />
            <CardContent>
                <Typography level="h6" sx={{ fontWeight: 'bold' }}>
                Search Engine Optimization
                </Typography>
                <Typography level="body2" sx={{ marginTop: '10px' }}>
                SEO: Elevating your online visibility and ranking for lasting digital success.
                </Typography>
            </CardContent>
            </Card>

            <Card 
            sx={{ 
                minWidth: 250, 
                flex: '1 1 calc(33.33% - 20px)', 
                borderRadius: '16px', 
                overflow: 'hidden', 
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                textAlign: 'center' 
            }}
            >
            <CardMedia
                component="img"
                src={card3}
                alt="Image 3"
                sx={{ 
                borderRadius: '50%', 
                width: '80px', 
                height: '80px', 
                objectFit: 'cover',
                margin: '20px 0' 
                }}
            />
            <CardContent>
                <Typography level="h6" sx={{ fontWeight: 'bold' }}>
                Google Ads
                </Typography>
                <Typography level="body2" sx={{ marginTop: '10px' }}>
                Precision campaigns for targeted visibility and strategic digital impact.
                </Typography>
            </CardContent>
            </Card>

            <Card 
            sx={{ 
                minWidth: 250, 
                flex: '1 1 calc(33.33% - 20px)', 
                borderRadius: '16px', 
                overflow: 'hidden', 
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                textAlign: 'center' 
            }}
            >
            <CardMedia
                component="img"
                src={card4}
                alt="Image 4"
                sx={{ 
                borderRadius: '50%', 
                width: '80px', 
                height: '80px', 
                objectFit: 'cover',
                margin: '20px 0' 
                }}
            />
            <CardContent>
                <Typography level="h6" sx={{ fontWeight: 'bold' }}>
                Web Hosting
                </Typography>
                <Typography level="body2" sx={{ marginTop: '10px' }}>
                Reliable infrastructure ensuring seamless performance and secure online presence.
                </Typography>
            </CardContent>
            </Card>

            <Card 
            sx={{ 
                minWidth: 250, 
                flex: '1 1 calc(33.33% - 20px)', 
                borderRadius: '16px', 
                overflow: 'hidden', 
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                textAlign: 'center' 
            }}
            >
            <CardMedia
                component="img"
                src={card5}
                alt="Image 5"
                sx={{ 
                borderRadius: '50%', 
                width: '80px', 
                height: '80px', 
                objectFit: 'cover',
                margin: '20px 0' 
                }}
            />
            <CardContent>
                <Typography level="h6" sx={{ fontWeight: 'bold' }}>
                E-Commerce Solutions
                </Typography>
                <Typography level="body2" sx={{ marginTop: '10px' }}>
                Elevate your online business with seamless, tailored digital transactions.
                </Typography>
            </CardContent>
            </Card>

            <Card 
            sx={{ 
                minWidth: 250, 
                flex: '1 1 calc(33.33% - 20px)', 
                borderRadius: '16px', 
                overflow: 'hidden', 
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                textAlign: 'center' 
            }}
            >
            <CardMedia
                component="img"
                src={card6}
                alt="Image 6"
                sx={{ 
                borderRadius: '50%', 
                width: '80px', 
                height: '80px', 
                objectFit: 'cover',
                margin: '20px 0' 
                }}
            />
            <CardContent>
                <Typography level="h6" sx={{ fontWeight: 'bold' }}>
                Facebook Ads
                </Typography>
                <Typography level="body2" sx={{ marginTop: '10px' }}>
                Strategic campaigns to amplify brand visibility and engagement.
                </Typography>
            </CardContent>
            </Card>
        </Box>
        <Typography variant='body1' sx={{color:'white',marginTop:'30px',marginRight:'30px',marginBottom:'20px',width:'95%',textAlign:'start'}}>Now that you know the best website Design Company in India, this is not enough for you to go and hire directly, before that you should know some of the basic knowledge about website development and process because in the market there are lots of fraud which you may face, if something like that happen you will know that what’s the process and they will not be able to fool you because i am assuming most of you are new or startup that’s why you are looking to build a website to grow your brand digitally and its a crucial time for any company in terms of budget also. So if you have the basic knowledge about what are building they it can save up to no limit.</Typography>
        </Box>


      </Box>
    </div>
  );
}

export default Home;

import { Box, Typography, Grid, List, ListItem, ListItemText } from '@mui/material';
import Image1 from '../../images/about.png'
const About = () => {
  return (
    <Box p={4} sx={{backgroundColor:'#0F1214'}}>
      {/* Content1 */}
      <Grid 
        container 
        spacing={2} 
        alignItems="center" 
        justifyContent="space-between" 
        direction={{ xs: 'column', md: 'row' }}
        color='white'
        marginTop='50px'
      >
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom sx={{fontWeight:'900'}}>
            About 24siteshop
          </Typography>
          <Typography variant="body1" paragraph>
            24Siteshop is the Best Website Development Company in Guwahati, Provides Website Development, SEO Services, PPC Ads, Social Media Marketing, and other Digital Marketing Services Across all India.
          </Typography>
          <Typography variant="body1" paragraph>
            Welcome to 24Siteshop, your trusted partner in creating impactful online experiences. As a premier website development company, we are dedicated to helping businesses of all sizes establish a strong digital presence and thrive in the competitive online landscape.
          </Typography>
          <Typography variant="body1" paragraph>
            At 24Siteshop, we are a team of passionate and skilled professionals committed to delivering top-notch web solutions tailored to meet your unique needs. Our expertise lies in website development and design, and we take pride in crafting visually appealing, highly functional, and user-friendly websites that leave a lasting impression.
          </Typography>
        </Grid>

        {/* Right Image */}
        <Grid item xs={12} md={6} display="flex" justifyContent="center">
          <img
            src={Image1}
            alt="Description"
            style={{ maxWidth: '100%', height: 'auto', objectFit: 'cover', maxHeight: '400px' }}
          />
        </Grid>
      </Grid>

      {/* Content2 */}
        <Box mt={4} sx={{ color: 'white' }}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: '900' }}>
            Our Services
          </Typography>
          <Typography>
            We offer a comprehensive range of services to ensure your online success:
          </Typography>
          <Grid container spacing={2} sx={{gap:'10px', paddingLeft: 2, marginTop: 2 }}>
            <ul>
              
            <Grid item xs={12}  sx={{ display: 'flex', alignItems: 'center' }}>
            <li>
              <Typography variant="body1" sx={{ fontWeight: 800}}>
                Website Development & Design :
              </Typography>
              </li>
              <Typography variant="body2" >
                Our core strength, where creativity meets functionality. We create stunning websites that not only look great but also perform flawlessly across all devices.
              </Typography>
            </Grid>
            <Grid item xs={12} component="li" sx={{ display: 'flex', alignItems: 'center' }}>
            <li>
              <Typography variant="body1" sx={{ fontWeight: 800,}}>
                Web Hosting :
              </Typography>
              </li>
              <Typography variant="body2" sx={{ ml: 1 }}>
                Reliable and secure web hosting solutions to keep your website up and running smoothly, ensuring fast load times and minimal downtime.
              </Typography>
            </Grid>
            <Grid item xs={12} component="li" sx={{ display: 'flex', alignItems: 'center' }}>
            <li>
              <Typography variant="body1" sx={{ fontWeight: 800}}>
                Payment Gateway 
              </Typography>
              </li>
              <Typography variant="body2" sx={{ ml: 1 }}>
                Seamless integration of secure payment gateways to facilitate smooth and safe online transactions for your e-commerce platform.
              </Typography>
            </Grid>
            <Grid item xs={12} component="li" sx={{ display: 'flex', alignItems: 'center' }}>
            <li>
              <Typography variant="body1" sx={{ fontWeight: 600, }}>
                Google Ads Service 
              </Typography>
            </li>
              <Typography variant="body2" sx={{ ml: 1 }}>
                Expert management of Google Ads campaigns to drive targeted traffic, increase visibility, and maximize your ROI.
              </Typography>
            </Grid>
            <Grid item xs={12} component="li" sx={{ display: 'flex', alignItems: 'center' }}>
            <li>
              <Typography variant="body1" sx={{ fontWeight: 800, }}>
                Facebook Ads Service :
              </Typography>
            </li>
              <Typography variant="body2" sx={{ ml: 1 }}>
                Strategically crafted Facebook ad campaigns to reach your audience effectively, boost engagement, and generate leads.
              </Typography>
            </Grid>
            <Grid item xs={12} component="li" sx={{ display: 'flex', alignItems: 'center' }}>
            <li>
              <Typography variant="body1" sx={{ fontWeight: 800, }}>
                One Time Basic SEO :
              </Typography>
              </li>
              <Typography variant="body2" sx={{ ml: 1 }}>
                Essential SEO services to optimize your website for search engines, improving your online visibility and ranking.
              </Typography>
            </Grid>
            <Grid item xs={12} component="li" sx={{ display: 'flex', alignItems: 'center' }}>
            <li>
              <Typography variant="body1" sx={{ fontWeight: 800 }}>
              E-Commerce Solutions :
              </Typography>
              </li>
              <Typography variant="body2" sx={{ ml: 1 }}>
              Comprehensive e-commerce solutions, including shopping cart integration, product catalog management, and secure checkout processes, to help you build and grow your online store.
              </Typography>
            </Grid>

            <Grid item xs={12} component="li" sx={{ display: 'flex', alignItems: 'center' }}>
            <li>
              <Typography variant="body1" sx={{ fontWeight: 800 }}>
                Search Engine Optimization (SEO) :
              </Typography>
              </li>
              <Typography variant="body2" sx={{ ml: 1 }}>
                Advanced SEO strategies to enhance your website’s organic search performance, driving more traffic and increasing conversions.
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
            <li>
              <Typography variant="body1" sx={{ fontWeight: 800, }}>
                Website Maintenance Plan :
              </Typography>
              </li>
              <Typography variant="body2" sx={{ ml: 1 }}>
                Ongoing website maintenance services to keep your site updated, secure, and running efficiently, allowing you to focus on your business.
              </Typography>
            </Grid>
            </ul>
          </Grid>
        </Box>

        {/* content 3 */}
        <Box sx={{ padding: '20px', color: '#fff' }}>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold',color:'white' }}>
        Our Mission
      </Typography>
      <Typography variant="body1" paragraph>
        Our mission is to empower businesses with cutting-edge web solutions that drive growth, enhance customer engagement, and deliver measurable results. We believe in building long-term partnerships with our clients, providing them with the tools and support they need to succeed in the digital world.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginTop: '30px' }}>
        Why Choose Us?
      </Typography>
      <Grid container spacing={2} sx={{ paddingLeft: 2, listStyleType: 'disc' }}>
        <Grid item xs={12} component="li" sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <Typography variant="body1" sx={{ fontWeight: 600, marginRight: '5px' }}>
            Expertise and Experience:
          </Typography>
          <Typography variant="body2">
            With years of experience in the industry, we have honed our skills and knowledge to deliver exceptional web solutions.
          </Typography>
        </Grid>
        <Grid item xs={12} component="li" sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <Typography variant="body1" sx={{ fontWeight: 600, marginRight: '5px' }}>
            Customer-Centric Approach:
          </Typography>
          <Typography variant="body2">
            We prioritize our clients’ needs and work closely with them to ensure their vision is realized.
          </Typography>
        </Grid>
        <Grid item xs={12} component="li" sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <Typography variant="body1" sx={{ fontWeight: 600, marginRight: '5px' }}>
            Innovative Solutions:
          </Typography>
          <Typography variant="body2">
            We stay ahead of the curve by adopting the latest technologies and trends to provide innovative and effective web solutions.
          </Typography>
        </Grid>
        <Grid item xs={12} component="li" sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <Typography variant="body1" sx={{ fontWeight: 600, marginRight: '5px' }}>
            Dedicated Support:
          </Typography>
          <Typography variant="body2">
            Our team is always available to offer support and guidance, ensuring your website remains at its best.
          </Typography>
        </Grid>
      </Grid>

      <Typography variant="body1" paragraph sx={{ marginTop: '20px' }}>
        Join us at 24Siteshop and take the first step towards building a powerful online presence. Let us help you turn your digital dreams into reality.
      </Typography>
      <Typography variant="body1" paragraph>
        For more information, feel free to contact us. We’re excited to collaborate with you and take your business to new heights.
      </Typography>
    </Box>
    </Box>
  );
};

export default About;

import { React, Component } from "react";
import {
  Typography,
  Grid,
  Box,
  Button,
  Divider,
  InputBase,
  IconButton
} from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
class Footer extends Component {
  render() {
    return  (
      <>
        {/* Footer */}
        <Box
            sx={{
              width: '100%',
              minHeight: {md:'300px', xs:'500px'}, 
              backgroundColor: '#000000',
              color: 'white',
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              gap: { xs: 2},
              overflow:'hidden'
            }}
          >
            {/* Left Section */}
            <Box
              sx={{
                marginTop: '20px', 
                marginLeft: '15px',
                maxWidth: { xs: '100%', md: '200px' },
                display: 'flex',
                flexDirection: 'column',
                gap: '10px', 
                textAlign: { xs: 'center', md: 'left' },
                mb: { xs: 2, md: 0 },
              }}
            >

              <Box sx={{ display: 'flex', alignItems: 'center', marginTop:'80px' }}>
                <input type="checkbox" id="check" name="check" value="check" style={{height:'20px'}}/>
                <label htmlFor="check" style={{ fontSize: '10px' }}>
                  I agree to the{' '}
                  <a style={{ color: 'white' }}>
                    <u>privacy policy</u>
                  </a>
                </label>
                <br />
              </Box>
            </Box>
            <Divider sx={{ my: 2, borderColor: 'whitesmoke' }} />

            {/* Right side */}
            <Box
            sx={{
              marginTop: '20px', 
              width: '100%',
              minHeight: '200px', 
              backgroundColor: '#000000',
              color: 'white',
              p: 2,
            }}
          >
            <Grid container spacing={2} wrap="nowrap" sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
              <Grid item xs={9} md={3}>
                <Typography variant="h6" sx={{ fontSize: '15px' }} textAlign='start' fontFamily= 'RobotoMedium,sans-serif' fontWeight="500">CONTACT US</Typography><br/>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems:'start',gap: '8px', marginTop: '5px' }}>
                  <Box sx={{display:'flex',alignItems:'center',gap:'7px'}}>
                    <Typography><LocationOnIcon/></Typography>
                  <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '10px' }}> Khoirabari, Jogen Rabha flat, Barjamuguri, Khoirabari, UDALGURI, Assam 784522
                  </a>
                  </Box>
                 <Box sx={{display:'flex',alignItems:'center',gap:'7px',justifyContent:'center'}}><Typography><LocalPhoneIcon /></Typography> <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '12px' }}> 9394847289</a></Box>
                 <Box sx={{display:'flex',alignItems:'center',gap:'7px'}}>
                  <Typography><EmailIcon/></Typography>
                  <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '12px' }}>contact@24siteshop.com</a>
                  </Box>
                </Box>
              </Grid>
              <Divider sx={{ my: 2, borderColor: 'whitesmoke' }} />

              <Grid item xs={12} md={3}>
                <Typography variant="h6" sx={{ fontSize: '15px' }} textAlign='start' fontFamily= 'RobotoMedium,sans-serif' fontWeight="300">QUICKLINKS</Typography><br/>
                <Box sx={{ display: 'flex', flexDirection: 'column',alignItems:'start', gap: 1, marginTop: '5px' }}>
                  <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '12px' }}>Pay Now</a>
                  <a href="/" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '12px' }}>Home</a>
                  <a href="/pricing" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '12px' }}>Pricing</a>
                  <a href="/services" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '12px' }}>Services</a>
                  <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '12px' }}>Our Reviews</a>
                  <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '12px' }}>My Downloads</a>
                  <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '12px' }}>My Account</a>
                </Box>
              </Grid>
              <Divider sx={{ my: 2, borderColor: 'whitesmoke' }} />

              <Grid item xs={12} md={3}>
                <Typography variant="h6" sx={{ fontSize: '15px' }} textAlign='start' fontFamily= 'RobotoMedium,sans-serif' fontWeight="300">LEGAL LINKS</Typography><br/>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems:'start',gap: 1, marginTop: '5px' }}>
                  <a href="/about" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '12px' }}>About us</a>
                  <a href="/contact" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '12px' }}>Contact Us</a>
                  <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '12px' }}>Privacy Policy</a>
                  <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '12px' }}>Terms and Conditions</a>
                  <a href="#" style={{ textDecoration: 'none', color: 'whitesmoke', fontSize: '12px' }}>Report and Policy</a>
                </Box>
              </Grid>
              <Divider sx={{ my: 2, borderColor: 'whitesmoke' }} />

              <Grid item xs={10} md={3}>
                <Typography variant="h6" sx={{ fontSize: '15px' }} textAlign='start' fontFamily= 'RobotoMedium,sans-serif' fontWeight="300">ABOUT US</Typography><br/>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'start', marginTop: '5px' }}>
                  <Typography variant="body1" sx={{fontSize:'12px',paddingBottom:'5px'}}>24site is the Best Website Development Company in Guwahati.Provides Website Development,SEO Services,PPC Ads, Social Media Marketing and other Digital Marketing Services.</Typography>
                </Box>
              </Grid>
             
            </Grid>
          </Box>
              
        </Box>

 

       

      </>
    );
  }
}

export default Footer;

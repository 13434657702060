import React, { useState } from 'react';
import { Container, Grid, Box, Button, Typography, Collapse, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const styles = {
  questionBox: {
    padding:'10px',
    width: '80vw',
    borderRadius: '4px',
    marginBottom: '10px',
    display: 'flex',
    fontFamily: 'GillSansSemiBold, sans-serif',
    fontSize: '3.55556vw',
    flexDirection: 'column',
    fontSize:{xs:'18px'}
  },
  questionHeader: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'GillSansSemiBold, sans-serif',
    fontSize: '5.55556vw',
    justifyContent: 'space-between',
    color: 'black',
  },
  expandableContent: {
    padding: '5px',
  },
};

function QuestionBox({ question, content, expanded, onToggle }) {
  return (
    <Box sx={styles.questionBox}>
      <Box sx={styles.questionHeader}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: 'GillSansSemiBold',
            fontSize: { xs: '2.7vw', md: '1.55556vw', }, // Increase font size for mobile
            backgroundColor: 'transparent',
            color: '#fff',
            paddingRight: '4%',
            paddingLeft: '0',
            fontWeight: '750',
          }}
        >
          {question}
        </Typography>
        <Button onClick={onToggle} sx={{ color: '#fff' }}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Button>
      </Box>

      <Collapse in={expanded}>
        <Box sx={styles.expandableContent}>
          <Typography
            variant="body2"
            sx={{
              fontFamily: 'Roboto-Regular',
              fontSize: {md:'1.3vw',xs:'2.7vw',sm:'2.4vw'},
            }}
          >
            {content}
          </Typography>
        </Box>
      </Collapse>
    </Box>
  );
}


export default function App() {
  const [expanded, setExpanded] = useState(null);

  const contents = [
   
    'The cost of hiring a website designer in India can vary depending on the complexity of the project and the experience of the designer. On average, you can expect to pay anywhere from Rs 5,000 to Rs 10,000 or more for a website design.',

    "The price for website Developer in India can vary depending on the features and services included. Some website builders offer plans starting from Rs 2999 to Rs 10,000 or more, depending on your requirements.",

    "Yes you can get contact +91 9394847289 for low cost website design agency to build your dream at affordable price ever.",

    "Web developers may charge anywhere from Rs 2999 to Rs 1,00,000 or more per website, depending on the complexity of the project and the developer's experience.",

    'Some of the best websites for web design include Behance, Dribbble, and Awwwards, where you can find inspiration and examples of great web design.',

    "The average hourly rate for website building can vary depending on the location and experience of the developer. In India, you can expect to pay anywhere from Rs 500 to Rs 5,000 per hour for website building services."
  ];

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <Container>
      <Box
  sx={{
    width: '100%',
    height:'100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  }}
>
  <Typography
    variant="h2"
    sx={{
      fontSize: { md: '2.08333vw', xs: '4.7vw' },
      color: '#fff',
      fontFamily: 'Gill Sans, Gill Sans MT, Calibri, sans-serif',
      paddingBottom: { md: '3.125vw', xs: '5.4vw' },
      fontWeight: '800',
      marginTop: { xs: '50px' },
    }}
  >
    Frequently Asked Questions
  </Typography>
  <Grid container spacing={1}>
    <Grid item xs={12} md={12}>
      <Box
        sx={{
          fontFamily: 'GillSansSemiBold',
          fontSize: { md: '5.55556vw' },
          backgroundColor: 'transparent',
          color: '#fff',
          paddingRight: '4%',
          paddingLeft: '0',
        }}
      >
        {[
          'What is the average cost of hiring a website designer in India?',
          'What is the price range for Website Designer in India? ', 
          'Can I get a low-cost web design service in India?',
          'What is the average charge of web developers per website?', 
          'What are some of the best websites for design?',
          'What is the average hourly rate for website building?'

        ].map((question, index) => (
          <React.Fragment key={index}>
            <QuestionBox
              question={question}
              content={contents[index]}
              expanded={expanded === index}
              onToggle={() => handleToggle(index)}
            />
            {index < contents.length - 1 && (
              <Divider
                sx={{
                  margin: '10px 0',
                  backgroundColor: 'black',
                  height: '2px',
                  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                }}
              />
            )}
          </React.Fragment>
        ))}
      </Box>
    </Grid>
  </Grid>
</Box>

    </Container>
  );
}

import React, { useState} from "react";
import { AppBar, Toolbar, Typography, Box, Divider, IconButton, Drawer, useMediaQuery} from "@mui/material";
import {Menu as MenuIcon } from "@mui/icons-material";
import { Link } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import logo1 from '../../images/24siteshop-logo.webp';

function Home() {
    const [drawerOpen, setDrawerOpen] = useState(false);
  
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <div style={{overflowX:'hidden'}}>
      {/* Navbar */}
      <AppBar position="fixed" elevation={1} sx={{ backgroundColor: "#000", color: 'white' ,overflow:'hidden'}}>
        <Toolbar sx={{ justifyContent: "space-between",width:'80%' }}>
          <Box display="flex" alignItems="center">
            <img src={logo1} alt="Logo" style={{ height: 40, verticalAlign: "middle", marginRight: 8 }} />
            <Box>
              <Typography variant="h6" component="div" sx={{ fontWeight: "bold" }}>24siteshop</Typography>
              <Typography variant="body2" sx={{ fontSize: 12, fontWeight: '800' }}>An ISO Certified Website Development Company</Typography>
            </Box>
          </Box>
          
          {isDesktop ? (
            <Box sx={{ display: 'flex', gap: 3 }}>
               <Link to="/" style={{ textDecoration: 'none' }}>
        <Typography variant="body1" sx={{ color: 'white', textDecoration: 'none' }}>Home</Typography>
              </Link>
              <Link to="/pricing" style={{ textDecoration: 'none' }}>
                <Typography variant="body1" sx={{ color: 'white', textDecoration: 'none' }}>Pricing</Typography>
              </Link>
              <Link to="/services" style={{ textDecoration: 'none' }}>
                <Typography variant="body1" sx={{ color: 'white', textDecoration: 'none' }}>Services</Typography>
              </Link>
              <Link to="/contact" style={{ textDecoration: 'none' }}>
                <Typography variant="body1" sx={{ color: 'white', textDecoration: 'none' }}>Contact</Typography>
              </Link>
              <Link to="/about" style={{ textDecoration: 'none' }}>
                <Typography variant="body1" sx={{ color: 'white', textDecoration: 'none' }}>About Us</Typography>
              </Link>
            </Box>
          ) : (
            <IconButton color="inherit" onClick={toggleDrawer}>
              <MenuIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer for mobile/tablet */}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
        <Box p={2} width="250px" textAlign="center" role="presentation" onClick={toggleDrawer} sx={{color:'white',backgroundColor:'#000'}}>
          <Typography variant="h6">Menu</Typography>
          <Divider sx={{ my: 1 }} />
          <Link to="/" style={{ textDecoration: 'none',color:'white' }}>
          <Typography variant="body1" sx={{ py: 1 }}>Home</Typography>
          </Link>
          <Link to="/pricing" style={{ textDecoration: 'none',color:'white' }}>
          <Typography variant="body1" sx={{ py: 1 }}>Pricing</Typography>
          </Link>
          <Link to="/service" style={{ textDecoration: 'none',color:'white' }}>
          <Typography variant="body1" sx={{ py: 1 }}>Services</Typography>
          </Link>
          <Link to="/contact" style={{ textDecoration: 'none',color:'white'}}>
          <Typography variant="body1" sx={{ py: 1 }}>Contact</Typography>
          </Link>
          <Link to="/about" style={{ textDecoration: 'none' ,color:'white'}}>
          <Typography variant="body1" sx={{ py: 1 }}>About Us</Typography>
          </Link>
        </Box>
      </Drawer>
    </div>
  );
}

export default Home;

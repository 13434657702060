import { Box, Typography, TextField, Checkbox, FormControlLabel, Button } from '@mui/material';
import React from 'react';
import {  Select, MenuItem,Grid } from '@mui/material';
import WhatsApp  from '../../images/wsp.png';
import Phone from '../../images/phone.png';
import Email from '../../images/email.png';
function NewsletterSubscriptionForm() {
  return (
    <>
    <Box sx={{ p: 3 }}>
      {/* Contact Info */}
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={4}>
          <Box sx={{ p: 2, textAlign: 'center', boxShadow: 3,marginTop:'80px'}}>
            <img src={WhatsApp} alt="WhatsApp" width="40" />
            <Typography sx={{fontWeight:'700'}}>+91 9394847289</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box sx={{ p: 2, textAlign: 'center', boxShadow: 3,marginTop:'80px' }}>
            <img src={Phone} alt="Phone" width="40" />
            <Typography sx={{fontWeight:'700'}}>+91 9394847289</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box sx={{ p: 2, textAlign: 'center', boxShadow: 3,marginTop:'80px' }}>
            <img src={Email} alt="Email" width="40" />
            <Typography sx={{fontWeight:'700'}}>contact@24siteshop.com</Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Form Section */}
      <Typography variant="h5" align="center" sx={{ fontWeight:'800',marginTop:'20px' }}>
        Give Us an Idea About Your Requirement
      </Typography>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Name" variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Phone Number" variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Email" variant="outlined" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Budget" variant="outlined" />
        </Grid>
        <Grid item xs={12} >
          <Select fullWidth >
            <MenuItem value="" disabled>Select Website Type</MenuItem>
            <MenuItem value="E-commerce">E-commerce</MenuItem>
            <MenuItem value="Portfolio">Portfolio</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Box sx={{ mt: 3, textAlign: 'center' }}>
        <Button variant="contained" color="primary">Submit</Button>
      </Box>
    </Box>

    <Box sx={{ padding: '30px', maxWidth: '500px', margin: 'auto', backgroundColor: '#f5f7fa', borderRadius: '8px' }}>
      {/* Title */}
      <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '10px' }}>
        24siteshop's Newsletter
      </Typography>
      
      {/* Subtitle */}
      <Typography variant="body1" sx={{ marginBottom: '20px' }}>
        Subscribe to our newsletter and stay updated.
      </Typography>
      
      {/* Email Label */}
      <Typography variant="body2" sx={{ fontWeight: 'bold', marginBottom: '5px' }}>
        Enter your email address to subscribe<span style={{ color: 'red' }}>*</span>
      </Typography>

      {/* Email Input Field */}
      <TextField
        variant="outlined"
        fullWidth
        placeholder="EMAIL"
        sx={{ marginBottom: '10px' }}
      />
      <Typography variant="caption" display="block" sx={{ color: '#666', marginBottom: '10px' }}>
        Provide your email address to subscribe.
      </Typography>

      {/* Checkbox Agreement */}
      <FormControlLabel
        control={<Checkbox />}
        label="I agree to receive your newsletters and accept the data privacy statement."
        sx={{ marginBottom: '10px', alignItems: 'flex-start' }}
      />
      <Typography variant="caption" display="block" sx={{ color: '#666', marginBottom: '20px' }}>
        You may unsubscribe at any time using the link in our newsletter.
      </Typography>


      <Typography variant="caption" display="block" sx={{ color: '#666', textAlign: 'center', marginBottom: '20px' }}>
        Form secured by reCAPTCHA
      </Typography>

      {/* Subscribe Button */}
      <Button
        variant="contained"
        fullWidth
        sx={{
          backgroundColor: '#333',
          color: '#fff',
          fontWeight: 'bold',
          padding: '10px',
          '&:hover': { backgroundColor: '#444' }
        }}
      >
        SUBSCRIBE
      </Button>
    </Box>
    </>
  );
}

export default NewsletterSubscriptionForm;

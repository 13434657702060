import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCn18s1ryVU1br5P2WgM29sqHraU8SCPgQ",
  authDomain: "jnineprojectpricing.firebaseapp.com",
  projectId: "jnineprojectpricing",
  storageBucket: "jnineprojectpricing.firebasestorage.app",
  messagingSenderId: "520354961698",
  appId: "1:520354961698:web:8d923f43e1076978b6ad7b",
  measurementId: "G-ZKQZ73NTDF"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Analytics and Storage
export const analytics = getAnalytics(app);
export const storage = getStorage(app);










export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const CAPTCHA = "CAPTCHA";
export const PASSWORD_GENERATE = "PASSWORD_GENERATE";

export const PROFILEDEATAILS = "PROFILEDEATAILS";
export const EDITPASSWORD = "EDITPASSWORD";

export const SET_ADMIN_ID = "SET_ADMIN_ID";
export const HANDLEDRAWER = "HANDLEDRAWER";
export const SETMOBILE = "SETMOBILE";
export const SET_ALL_ORG = "SET_ALL_ORG";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_ORG_DETAILS = "SET_ORG_DETAILS";
export const SET_ORG_USER_DEATILS = "SET_ORG_USER_DEATILS";
export const SET_ORG_ID = "SET_ORG_ID";
export const SET_ALL_DATA = "SET_ALL_DATA";
export const SET_USER = "SET_USER";
export const SET_ORG = "SET_ORG";
export const SETLOGINORG = "SETLOGINORG";
export const SET_ORG_USER_DETAILS = "SET_ORG_USER_DETAILS";
export const SET_ORG_DETAILS2 = "SET_ORG_DETAILS2";
export const VIEW_ALL_DEPARTMENT = "VIEW_ALL_DEPARTMENT";
export const VIEW_ALL_PRIORITY = "VIEW_ALL_PRIORITY";
export const VIEW_ALL_STATUS = "VIEW_ALL_STATUS";
export const SET_DOC = "SET_DOC";
export const LOGIN_STATUS = "LOGIN_STATUS";
export const SET_PASSWORD = "SET_PASSWORD";
export const SET_EMAIL = "SET_EMAIL";
export const SET_HOME = "SET_HOME";
export const SET_ORGANIZATIONS = "SET_ORGANIZATIONS";
export const SET_WALET_BY_ID = "SET_WALET_BY_ID";
import React,{useState} from 'react';
import { Grid, Typography, Box,List,ListItem,Card,CardContent,Divider,useMediaQuery,IconButton } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import { ArrowBackIos, ArrowForwardIos,  } from "@mui/icons-material";
import Service from '../../images/service.jpg'
import contact from '../../images/contact.png'
import website from '../../images/website.png';
import GoogleImg from '../../images/google.png'
import SEOImg from '../../images/seo.webp';
import FirewallImg from '../../images/firewall.png';
import SSLImg from '../../images/ssl.png'
const ServicePage = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
  
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
    const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const visibleCards = isDesktop ? 4 : isTablet ? 2 : 1;
  

  const cardData = [
    {
      title: "Bronze Website Development",
      subtitle: "One Page Website",
      price: "₹24,999",
      features: [
        "One Page Website",
        "Free Domain 1 Year*",
        "Free Hosting 1 Year",
        "Contact Form",
        "Responsive Design",
        "Professional Email Id 2",
      ],
      contact: "+91-9555-993-311",
    },
    {
      title: "Silver Website Development",
      subtitle: "Multi Page Website",
      price: "₹34,999",
      features: [
        "7 Pages Business website",
        "Customized Design",
        "Free Hosting 1 Year",
        "Free Domain 1 Year",
        "SEO Ready Website",
        "Business Email Id 10",
        "SSL Certificate",
        "Control Panel"
      ],
      contact: "+91-9555-993-311",
    },
    {
      title: "Gold Website Development",
      subtitle: "E-commerce Website",
      price: "₹54,999",
      features: [
        "E-commerce Website",
        "Customized Design",
        "Free Hosting 1 Year",
        "Free Domain 1 Year",
        "SEO Ready Website",
        "Business Email 10",
        "SSL Certificate",
        "Control Panel"
      ],
      contact: "+91-9555-993-311",
    },
    {
      title: "Gold+ Website Development",
      subtitle: "E-commerce Website",
      price: "₹99,999",
      features: [
        "E-commerce Website",
        "Wallet and Referral Integration",
        "Abandoned Checkout",
        "Customized Design",
        "Free Hosting 1 Year",
        "Free Domain 1 Year",
        "SEO Ready Website",
        "Business Email 15",
      ],
      contact: "+91-9555-993-311",
    },
    {
      title: "Platinum Website Development",
      subtitle: "Advanced E-commerce Website + Android App",
      price: "₹139,999",
      features: [
        "E-commerce Website",
        "Wallet and Referral Integration",
        "Abandoned Checkout",
        "Android Hybrid App",
        "Google Play Publishing",
        "Free Hosting 1 Year",
        "Free Domain 1 Year",
      ],
      contact: "+91-9555-993-311",
    },
    {
      title: "Diamond Website Development",
      subtitle: "Advanced E-commerce Website + iOs Hybrid App",
      price: "₹149,999",
      features: [
        "E-commerce Website",
        "Wallet and Referral Integration",
        "Abandoned Checkout",
        "Android Hybrid App",
        "Google Play Publishing",
        "Free Hosting 1 Year",
        "Free Domain 1 Year",
      ],
      contact: "+91-9555-993-311",
    },
  ];
  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : cardData.length - visibleCards));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < cardData.length - visibleCards ? prevIndex + 1 : 0));
  };
  return (
    <Box sx={{ padding: '2rem',backgroundColor:'#0F1214',color:'#fff',overflowX:'hidden' }}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={Service} 
            alt="Web Development Services"
            sx={{
              width: '100%',
              borderRadius: '8px',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              marginTop:'50px'
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h4" sx={{ fontWeight: 'bold', marginBottom: '1rem',marginTop:'50px' }}>
            Looking for Website Development and Design Services in India?
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
            Are you a business owner? Or any kind of services or goods you are selling and want to take it online? Well, you have come to the right place then. We are a website development and design service provider in India. In this digital era, a website is the most important thing to grow your business online as we all know.
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: '1rem' }}>
            But the issue is when we go online and try to find a good website development agency, that’s when the main issue occurs. I mean, there are thousands of website design and development agencies that are fraudulent in this business.
          </Typography>
        </Grid>

        {/* Second Section with another Image */}
        <Grid item xs={12}>
          <Box
            component="img"
            src={contact} 
            alt="Contact for Website Services"
            sx={{
              width: '100%',
              borderRadius: '8px',
              boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              marginTop: '2rem'
            }}
          />
        </Grid>

        <Box sx={{ padding: '2rem', }}>
      <Grid container spacing={4} alignItems="center" justifyContent="center">
        
        {/* Text Content */}
        <Grid item xs={12} md={6.7} >
          <Typography variant="h4" component="h2" gutterBottom>
            What is a Website?
          </Typography>
          <Typography variant="body1" paragraph>
            Website is a place or shop of your business on the internet, it will have a web address
            like www.xyz.com using which users can go to your business or shop over the internet.
            That's the meaning of a website in simple words. But if you prefer English, here it is 😁 
            “A website is a collection of related web pages that are stored on a server and can be 
            accessed over the internet. Each web page typically contains text, images, videos, or other 
            multimedia content and is designed to be viewed in a web browser. Websites are created for 
            various purposes, such as providing information, selling products or services, sharing ideas, 
            or connecting people.”
          </Typography>
        </Grid>

        {/* Image Content */}
        <Grid item xs={12} md={3.6} >
          <Box
            component="img"
            src={website}
            alt="Website Info Graphic"
            sx={{ width: '100%', maxHeight: '300px', objectFit: 'cover', borderRadius: '8px' }}
          />     
        </Grid>

        {/* Additional Sections */}
        <Grid item xs={12} md={10.7}>
          <Box sx={{ padding: 4 }}>
            <Typography variant="h4" component="h2" gutterBottom>
              Why Your Business Needs a Website?
            </Typography>
            <Typography variant="body1">
              Think about starting a business like opening a shop. If it’s a physical store, you’d have 
              to spend a lot on rent, utilities, and other expenses. It’s pricey and limits you to one 
              location. Now, imagine a website as a smart, budget-friendly choice. It’s like a virtual 
              shop that people can visit from anywhere. No need for a specific place, and you save a ton 
              on costs. Your business isn’t stuck in one spot; it can reach people globally. Having a 
              website is like a clever investment – it’s affordable, boosts visibility, and opens doors 
              to customers all around the online world.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={10.7}>
          <Box sx={{ padding: 3 }}>
            <Typography variant="h4" component="h2" gutterBottom>
              How to Find the Right Website Development and Design Services in Guwahati?
            </Typography>
            <Typography variant="body1">
              In the website game, scams are everywhere. Our crew at 24siteshop.com keeps it real, 
              offering legit services at wallet-friendly rates. We don’t just talk the talk; we walk 
              it too. Check out our live demos on YouTube where we show our users how we created our 
              last website, for every website we show to users that, that’s how we created this client 
              website and it was his needs. It’s like a backstage pass to see exactly what you’re 
              getting. No smoke and mirrors, just the real deal. Because we believe in being upfront 
              and showing off our work, so you know you’re getting top-notch stuff without breaking 
              the bank. Trust us, we’ve got your back in the web world.
            </Typography>
          </Box>
        </Grid>

        {/* Ads card */}
        <Card sx={{ padding: 4, borderRadius: '16px', boxShadow: 3, textAlign: 'center', maxWidth: 800, marginBottom:'20px' }}>
      <Typography variant="h5" gutterBottom>
        What We Offer Along With Website
      </Typography>
      
      <Grid container spacing={4} justifyContent="center">
        
        {/* Google Ads */}
        <Grid item xs={6} sm={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box component="img" src={GoogleImg} alt="Google Ads" sx={{ width: 50, height: 50 }} />
            <Typography variant="body1">₹ Free (Google Ads)</Typography>
          </Box>
        </Grid>
        
        {/* SEO Tools */}
        <Grid item xs={6} sm={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box component="img" src={SEOImg} alt="SEO Tools" sx={{ width: 50, height: 50 }} />
            <Typography variant="body1">₹ Free (Premium SEO Tools)</Typography>
          </Box>
        </Grid>
        
        {/* Firewall */}
        <Grid item xs={6} sm={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box component="img" src={FirewallImg} alt="Firewall Security" sx={{ width: 50, height: 50 }} />
            <Typography variant="body1">₹ Free (Firewall for Security)</Typography>
          </Box>
        </Grid>
        
        {/* SSL Certificate */}
        <Grid item xs={6} sm={3}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box component="img" src={SSLImg} alt="SSL Certificate" sx={{ width: 50, height: 50 }} />
            <Typography variant="body1">₹ Free (SSL Certificate)</Typography>
          </Box>
        </Grid>
        
      </Grid>
    </Card>


              {/* Carousel Section */}
      <Box display='flex' flexDirection="column" justifyContent='center'>
      <Typography variant="h3" sx={{textAlign:'center',color:'#54595F',fontFamily:"Montserrat, Sans-serif",fontSize:{xs:'25px',sm:'27px',lg:'30px'},fontWeight:'700',color:'white'}}>Pricing Plan for Website</Typography>
      <Box display="flex" alignItems="center" justifyContent="center" sx={{alignItems:"center",overflowX:'hidden'}} p={2}>
        <IconButton onClick={handlePrev} disabled={currentIndex === 0}>
          <ArrowBackIos sx={{color:'white'}} />
        </IconButton>

        <Box display="flex" gap={2} sx={{ overflow: "hidden", width: "80%" }}>
          {cardData.slice(currentIndex, currentIndex + visibleCards).map((card, index) => (
            <Card key={index} sx={{ width: "300px", backgroundColor: "#000", color: "#fff", border: "1px solid #00f", borderRadius: "8px" }}>
              <CardContent sx={{ textAlign: "center" }}>
                <Typography variant="h6" sx={{ fontWeight: "bold", color: "#fff", fontWeight: 'bolder' }}>
                  {card.title}
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "#bbb" }}>
                  {card.subtitle}
                </Typography>
                <Typography variant="h4" sx={{ fontWeight: "bold", margin: "16px 0" }}>
                  {card.price}
                </Typography>
                <List dense sx={{ color: "#ccc" }}>
                  {card.features.map((feature, idx) => (
                    <ListItem key={idx} sx={{ padding: 0, fontSize: "14px" }}>
                      • {feature}
                    </ListItem>
                  ))}
                </List>
                <Divider sx={{ backgroundColor: "#333", margin: "16px 0" }} />
                <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
                  TALK TO US
                </Typography>
                <Typography variant="subtitle2" sx={{ marginTop: "8px" }}>
                  {card.contact}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>

        <IconButton onClick={handleNext} disabled={currentIndex === cardData.length - visibleCards}>
          <ArrowForwardIos sx={{color:'white'}} />
        </IconButton>
      </Box>
      </Box>
        

      </Grid>
    </Box>
        
      </Grid>
    </Box>
  );
};

export default ServicePage;
